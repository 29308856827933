import { gql } from 'apollo-angular';

export const massive_orders = gql`
  query FilterMassiveTxns($data: FilterOrdersByCriteriaInputData!) {
    filterMassiveTxns(data: $data) {
      body {
        items {
          id
          orderId
          orderProcessType
          orderType
          orderName
          orderAdditionalData {
            totalRecord
            totalAmount
          }
          orderArtifactsStoreUrl
          isActive
          status
          approvalStatus
          createdByUser
          createdOnDate
          reviewedByUser
          reviewedOnDate
          reviewedComments
          lastStatusChangeDate
          searchKey
        }
        meta {
          itemCount
          totalItems
          itemsPerPage
          totalPages
          currentPage
        }
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;

export const massive_pts_orders = gql`
  query FilterAdjustmentOrderRequests(
    $data: OrderMassiveFilterCriteriaInputData!
  ) {
    filterAdjustmentOrderRequests(data: $data) {
      body {
        items {
          id
          orderId
          custDocTypeId
          custDocId
          custAccountNo
          requestAmount
          requestDescription
          requestErrorCode
          requestErrorDescription
          requestId
          processStatus
          requestStatus
          searchKey
          extraData {
            adjustments {
              id
              date
            }
          }
          processAmount
        }
        meta {
          itemCount
          totalItems
          itemsPerPage
          totalPages
          currentPage
        }
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;

export const review_decision = gql`
  mutation ReviewDecisionMassiveTxn($data: UpdateOrderStatusInputData!) {
    reviewDecisionMassiveTxn(data: $data) {
      body {
        id
        orderId
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;

export const massive_delete = gql`
  mutation DeleteMassiveTxnOrder($data: DeleteOrderInputData!) {
    deleteMassiveTxnOrder(data: $data) {
      body {
        id
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;
export const massive_createpresign = gql`
  mutation createPreSignUrlForMassiveTxnUpload(
    $data: CreatePreSignUrlInputData!
  ) {
    createPreSignUrlForMassiveTxnUpload(data: $data) {
      body {
        id
        orderArtifactsBucket
        orderArtifactsStoreUrl
        orderId

        contentsUrl {
          type
          value
        }
      }
      status {
        code
        description
        httpStatus
      }
    }
  }
`;

export const massive_download = gql`
  query DownloadMassiveTxnContent($data: DownloadMassiveTxnContentInputData!) {
    downloadMassiveTxnContent(data: $data) {
      body {
        url
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;

export const create_adjustment = gql`
  mutation CreateMassiveTxnOrder($data: CreateAdjustmentOrderInputData!) {
    createMassiveTxnOrder(data: $data) {
      body {
        id
        orderId
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;
