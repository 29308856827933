export enum OrderProcessType {
  RECONCILE = 'RECONCILE',
  JUDICIAL = 'JUDICIAL',
  CLOSEACCOUNT = 'CLOSE-ACCOUNT',
  RECOVERY = 'AMOUNT_RECOVERY',
}
export enum PaymentType {
  TOTAL = 'TOTAL',
  PARTIAL = 'PARTIAL',
}

export enum ReviewDecision {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface OrderList {
  items: IQueryDataOrders[];
}
export interface IQueryDataOrders {
  start?: any;
  end?: any;
  filterCriteriaText?: string;
  createdByUser?: string;
  orderProcessType: OrderProcessType;
  orderType?: string;
  status?: string;
  skip: number;
  limit: number;
}

export interface IQueryDataOrdersPts {
  orderId: string;
  filterCriteriaText?: string;
  requestStatus?: string;
  processStatus?: string;
  skip: number;
  limit: number;
}
export interface IMutationReviewDecision {
  id: string;
  reviewDecision: string;
  reviewComments?: string;
}

export interface DataPages {
  skip: number;
  limit: number;
}

export interface OrderListResponse {
  filterMassiveTxns: ItemsDataGeneralMassive;
}

export interface AdjustmenOrderResponse {
  filterAdjustmentOrderRequests: ItemsDataGeneralPts;
}
export interface AccountOrderListResponse {
  meta: Meta;
  items: DataAccountOrders[];
}

export interface OrderAccountListResponse {
  filterAccountMgmts: AccountOrderListResponse;
}
export interface OrderList {
  items: IQueryDataOrders[];
}
export interface Meta {
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}
export interface DataAccountOrders {
  id: string;
  orderId: string;
  orderProcessType: string;
  orderType: string;
  orderName: string;
  orderAdditionalData?: {
    originalTransactionRefId: string;
    originalTransactionUUId: string;
    reverseReason: string;
    __typename?: string;
  };
  processingResponseData?: {
    amount: string;
    source: string;
    responseCode: string;
    responseDesc: string;
    extReferenceId: string;
    extReferenceUUID: string;
  };
  orderArtifactsStoreUrl: string;
  isActive: boolean;
  status: string;
  approvalStatus: string;
  createdByUser: string;
  createdOnDate: Date;
  reviewedByUser: string;
  reviewedOnDate: Date;
  reviewedComments: string;
  lastStatusChangeDate: Date;
  searchKey: string;
  custDocId: string;
  custDocTypeId: string;
  custName: string;
}

export interface OrderData {
  id: string;
  orderId: string;
  orderProcessType: string;
  orderType: string;
  orderName: string;
  orderAdditionalData?: {
    totalAmount: number;
    totalRecord: number;
    __typename?: string;
  };
  orderArtifactsStoreUrl: string;
  isActive: boolean;
  status: string;
  createdByUser: string;
  reviewedByUser: string;
  createdOnDate: Date;
  reviewedOnDate: Date;
  reviewedComments: string;
  lastStatusChangeDate: Date;
  searchKey: string;
}

export interface OrderDataPts {
  id: string;
  orderId: string;
  custDocTypeId: string;
  custDocId: string;
  custAccountNo: string;
  requestAmount: number;
  requestDescription: string;
  requestErrorCode: string;
  requestErrorDescription: string;
  requestId: string;
  requestStatus: string;
  processStatus: EnumOrderProcessStatus;
  searchKey: string;
  __typename?: string;
  extraData: ExtrDataDto;
  processAmount: number;
}
export enum EnumOrderProcessStatus {
  NOT_APPLY = 'NOT_APPLY',
  TOTAL = 'TOTAL',
  PARTIAL = 'PARTIAL',
  FAILED = 'FAILED',
}
export interface ExtrDataDto {
  adjustments: AjustmentDto[];
}
export interface AjustmentDto {
  id: string;
  date?: string;
}
export interface ItemsDataGeneralMassive {
  body: ItemsDataTable;
  status: ResponseErrorGql;
}

export interface ItemsDataTable {
  items: OrderData[];
  meta: Meta;
}

export interface ItemsDataGeneralPts {
  body: ItemsDataTablePts;
  status: ResponseErrorGql;
}

export interface ItemsDataTablePts {
  meta: Meta;
  items: OrderDataPts[];
}

export interface ResponseErrorGql {
  httpStatus: any;
  code: string;
  description: string;
}

export interface QueryDataDownloadMassive {
  id: string;
  orderType: string;
  orderId: string;
  contentType: string;
}
export interface OrdersDecision {
  id: string;
  orderId: string;
}
export interface AccountOrderResultResponse {
  body: OrdersDecision[];
  status: ResponseErrorGql;
}

export interface UpdateOrderStatusResponse {
  reviewDecisionAccount: AccountOrderResultResponse;
}
export enum TabListPlaceholderEnum {
  RECONCILE = 'Busca por número o ejecutor de la orden.',
  JUDICIAL = 'Busca por número o ejecutor de la orden.',
  CLOSEACCOUNT = 'Busca por ID del cliente, número o ejecutor de la orden.',
  RECOVERY = 'Escribe un número de orden.',
}

export enum StrategyFileEnum {
  MASSIVE_ADJUSTMENT = 'massiveAdjustment',
}
