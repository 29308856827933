<div class="grid text-center pt-2">
    <div class="col-8 col-offset-2">
      <img oading="lazy" [src]="img" alt="image" class="w-6" />
    </div>
    <div class="col-8 col-offset-2 pt-1">
      <div class="fontHeader">
        {{ title }}
      </div>
      <div class="fontSubHeader" style="padding-top: 3%">
        {{ subtitle }}
      </div>
    </div>
    <div class="col-6 col-offset-2 pt-4">
      <div class="grid">
            <div class="col-6 col-offset-5 pt-4">
                <deuna-button label="+  Nueva orden" [outline]="true" (eventClick)="continueBack()"  routerLink="/people/incidentorders/create-order/step-1">
                </deuna-button>
              </div>
    </div>
  </div>
