import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { debounceTime } from 'rxjs';
import { GraphqlMassivetxnsService } from 'src/app/services/graphql/graphql-massivetxns.service';
import {
  ModalParamsLoadingError,
  ModalType,
} from 'src/app/shared/models/constants.backoffice.schema';
import {
  DataPages,
  IQueryDataOrdersPts,
  ItemsDataTablePts,
  OrderProcessType,
} from 'src/app/shared/models/data-table.schema';
import { ImageSets } from 'src/app/shared/models/image-sets.schema';
import {
  filterPtsModalNew,
  filterPtsModalOld,
  judicialTypeNew,
  massivePtsColumnsGeneral,
  massivePtsColumnsGeneralNew,
  massivePtsColumnsIntRe,
  massivePtsColumnsRecovery,
} from 'src/app/shared/models/order-types.schema';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-massive-pts-modal',
  templateUrl: './massive-pts-modal.component.html',
  styleUrls: ['./massive-pts-modal.component.scss'],
})
export class MassivePtsModalComponent {
  @Input() displayModal: boolean = false;
  @Output() onHide = new EventEmitter();
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() loading: boolean = false;
  @Input() orderId: string = '';
  @Input() mailExecutor: string = '';
  @Input() mailApprove: string = '';
  @Input() statusOrder: string = '';
  @Input() orderType: string = '';
  @Input() orderProcessType: string = '';
  @Input() order: any;

  showDiv = false;

  showSideBar: boolean = false;

  ordersListPts: ItemsDataTablePts;
  actionTypeEnum = OrderProcessType;
  showFilterBtn = environment.enabledPtsFilter;
  activeFilters: any[] = [];
  statusList: any[] = filterPtsModalOld;

  constructor(
    private fb: FormBuilder,
    private servicesMassivetxnsGql: GraphqlMassivetxnsService
  ) {
    this.formFilterOrderList = this.fb.group({
      textSearchOrders: [''],
    });

    this.paramsQueryPts = {
      // Agrega esta inicialización
      orderId: this.orderId,
      filterCriteriaText: '',
      skip: 0,
      limit: 10,
    };

    this.ordersListPts = {
      items: [],
      meta: {
        itemCount: 0,
        totalItems: 0,
        itemsPerPage: 0,
        currentPage: 0,
        totalPages: 0,
      },
    };
  }

  inputSub: any;

  ngOnInit(): void {
    this.createConfigGetInitial();
    this.validationColumsByOrderProcessType();
  }

  pointBrown: string = assetUrl('svg/dot-brown.svg');
  pointGreen: string = assetUrl('svg/dot-green.png');
  pointRed: string = assetUrl('svg/dot-red.svg');
  pointBlue: string = assetUrl('svg/dot-blue.svg');
  imageSee: string = assetUrl('svg/see.svg');

  imageLoadError: string = assetUrl('png/image-report-error-r.png');
  imageReload: string = assetUrl('svg/return.svg');
  imageClearFilters: string = assetUrl('svg/trash-white.svg');
  iconPaths = ImageSets.icons;

  searchByFiltersEmpty: boolean = false;
  showFilters: boolean = false;

  ngAfterContentInit() {
    this.suscribeToInputChanges();
  }

  suscribeToInputChanges() {
    const textSearchOrdersControl = this.formFilterOrderList.get(
      'textSearchOrders'
    ) as FormControl | null;

    if (textSearchOrdersControl) {
      this.inputSub = textSearchOrdersControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value) => {
          this.paramsQueryPts.skip = 0;
          this.paramsQueryPts.filterCriteriaText = value;
          this.getOrdersByPageMassivePts();
          this.searchByFiltersEmpty = true;
        });
    }
  }

  formFilterOrderList: FormGroup;

  titleSearch = 'Buscar por nro. de documento, nro. cuenta, o ref id';
  modalSelectorTypes: ModalType = ModalType.NotModal;
  loadingErrorProps: ModalParamsLoadingError = {
    image: '',
    subHeaderText: '',
    headerText: undefined,
    valueAction: '',
    text: '',
    orderId: '',
  };

  initNotOrdersPropsModal() {
    this.loadingErrorProps = {
      image: this.imageLoadError,
      text: 'Volver a cargar',
      valueAction: this.imageReload,
      headerText: 'Ocurrió un error al cargar tus órdenes',
      subHeaderText: 'Intenta cargar nuevamente para visualizar tus órdenes.',
      orderId: this.paramsQueryPts.filterCriteriaText,
    };
  }
  loadListBranchs: boolean = true;
  dataLoad: boolean = false;
  isRetencionActiva: boolean = false;

  orderTypeListNew = [
    'INTRETENCIONINACTIVAS',
    'INTRETENCIONINACTIVASRV',
    'INTRETENCIONJUDICIAL',
    'INTRETENCIONJUDICIALRV',
  ];

  columnsGeneral: string[] = massivePtsColumnsGeneral;
  isNewColumns = false;
  paramsQueryPts: IQueryDataOrdersPts = {
    orderId: this.orderId,
    filterCriteriaText: '',
    skip: 0,
    limit: 10,
    requestStatus: '',
    processStatus: '',
  };

  currentPage: number = 0;
  limitPage: number = 10;
  itemCount: number = 0;
  totalItems: number = 0;
  itemsPerPage: number = 0;
  totalPages: number = 0;
  dataPagination!: DataPages;
  columns: string[] = [];

  orderTypeList =
    'INTAJUSTERECLASI, INTRECLASIFFALTANTE,INTRECLASIFSOBRANTE,INTREGFALTANTECOMERCIO,INTAJUSTERECLASIRV,INTRECLASIFFALTANTERV,INTRECLASIFSOBRANTERV,INTREGFALTANTECOMERCIORV';

  validationColumsByOrderProcessType() {
    this.isRetencionActiva = this.orderType === judicialTypeNew[0].name;
    const orderTypeList = this.orderTypeList.split(',');
    this.isNewColumns = this.orderTypeListNew.includes(this.orderType);
    this.columnsGeneral = this.isNewColumns
      ? massivePtsColumnsGeneralNew
      : massivePtsColumnsGeneral;
    // SET MAIN
    this.columns = orderTypeList.includes(this.orderType)
      ? [...massivePtsColumnsIntRe]
      : [...this.columnsGeneral];
    this.columns =
      this.orderProcessType === OrderProcessType.RECOVERY
        ? massivePtsColumnsRecovery
        : this.columns;
    this.statusList = this.isRetencionActiva
      ? filterPtsModalNew.map((item) => ({ ...item, selected: false }))
      : filterPtsModalOld.map((item) => ({ ...item, selected: false }));
  }

  isOrderTypeIncluded(orderType: string): boolean {
    const orderTypeList = this.orderTypeList.split(',');
    return orderTypeList.includes(orderType);
  }

  showDownloadFiles(): boolean {
    return (
      this.orderProcessType === OrderProcessType.JUDICIAL ||
      this.orderProcessType === OrderProcessType.RECOVERY
    );
  }

  initNotOrdersSearchPropsModal() {
    this.loadingErrorProps = {
      image: this.imageLoadError,
      text: 'Realizar otra busqueda',
      valueAction: this.imageClearFilters,
      headerText: `No encontramos ${this.paramsQueryPts.filterCriteriaText} entre tus ordenes `,
      subHeaderText: '',
      orderId: this.paramsQueryPts.filterCriteriaText,
    };
  }

  orderTypeClean: any;

  createConfigGetInitial() {
    this.paramsQueryPts = {
      orderId: this.orderId,
      skip: this.currentPage,
      limit: this.limitPage,
    };

    this.dataPagination = {
      skip: this.currentPage,
      limit: this.limitPage,
    };

    this.getOrdersByPageMassivePts();
    this.validationColumsByOrderProcessType();
  }

  // Agrega esta variable a la clase de tu componente
  showErrorDetails: boolean = false;
  selectedIndex: number = 0;

  handleClickDetails(index: number) {
    this.showErrorDetails = !this.showErrorDetails;
    this.selectedIndex = index;
  }

  getOrdersByPageMassivePts() {
    this.loadListBranchs = true;
    this.dataLoad = false;
    this.modalSelectorTypes = ModalType.NotModal;

    this.servicesMassivetxnsGql
      .getOrdersMassivePts(this.paramsQueryPts)
      .subscribe({
        next: (res: ItemsDataTablePts) => {
          this.ordersListPts = res;

          if (!this.ordersListPts.items.length && !this.searchByFiltersEmpty) {
            this.dataLoad = true;
            this.initNotOrdersPropsModal();
            this.modalSelectorTypes =
              this.paramsQueryPts.processStatus ||
              this.paramsQueryPts.requestStatus
                ? ModalType.NotOrdersSearch
                : ModalType.NotCreateOrders;
          }
          if (!this.ordersListPts.items.length && this.searchByFiltersEmpty) {
            this.dataLoad = true;
            this.initNotOrdersSearchPropsModal();
            this.modalSelectorTypes = ModalType.NotOrdersSearch;
          }

          this.loadListBranchs = false;
        },
        error: () => {
          this.loadListBranchs = false;
          this.dataLoad = true;
          this.initNotOrdersPropsModal();
          this.modalSelectorTypes = ModalType.NotOrders;
        },
      });
  }

  goToPage(page: number) {
    if (page === 1) {
      this.paramsQueryPts.skip = 0;
    } else {
      this.paramsQueryPts.skip = this.paramsQueryPts.limit * (page - 1);
    }
    this.getOrdersByPageMassivePts();
  }
  changeToItems(page: number) {
    if (page === 0 || page === null) {
      this.paramsQueryPts.limit = 1;
    } else {
      this.paramsQueryPts.limit = page;
    }
    this.paramsQueryPts.skip = 0;
    this.getOrdersByPageMassivePts();
  }
  deleteFiltersReload() {
    this.loadListBranchs = true;
    this.deleteFilters();
    this.clearAllFiltersModal();
    setTimeout(() => {
      this.getOrdersByPageMassivePts();
    }, 200);
  }

  hideModal() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  showModal() {
    this.displayModal = true;
  }

  cancel() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  confirmCancellation() {
    this.onConfirm.emit();
  }

  reloadComponent() {
    this.dataLoad = true;
    this.getOrdersByPageMassivePts();
  }

  deleteFilters() {
    this.formFilterOrderList.controls['textSearchOrders'].setValue('');
    this.createConfigGetInitialClean();
  }

  createConfigGetInitialClean() {
    this.paramsQueryPts = {
      orderId: this.orderId,
      filterCriteriaText: '',
      skip: 0,
      limit: 10,
    };

    this.dataPagination = {
      skip: this.currentPage,
      limit: this.limitPage,
    };
  }
  openFilter() {
    this.showFilters = true;
  }
  closeFilter() {
    this.showFilters = false;
  }
  clearFilter(name: string) {
    const tempStatus = this.updateFiltersWithActiveFilters(
      this.statusList,
      this.activeFilters
    );
    this.statusList = tempStatus.map((item) =>
      item.name === name ? { ...item, selected: false } : item
    );
    const tempActivev = this.statusList.filter((r) => r.selected);
    this.onFilterChange(tempActivev);
  }
  clearAllFiltersModal() {
    this.statusList = this.isRetencionActiva
      ? filterPtsModalNew.map((item) => ({ ...item, selected: false }))
      : filterPtsModalOld.map((item) => ({ ...item, selected: false }));
    this.activeFilters = this.statusList.filter((r) => r.selected);
  }
  onFilterChange(filters: any[]) {
    this.activeFilters = filters;
    if (this.isRetencionActiva) {
      this.paramsQueryPts.processStatus = filters
        .map((items) => items.value)
        .toString();
      this.getOrdersByPageMassivePts();
    } else {
      this.paramsQueryPts.requestStatus = filters
        .map((items) => items.value)
        .toString();
      this.getOrdersByPageMassivePts();
    }
  }
  updateFiltersWithActiveFilters = (
    filters: { name: string; selected: boolean; value: string }[],
    activeFilters: { name: string; selected: boolean; value: string }[]
  ) => {
    return filters.map((filter) => {
      const activeFilter = activeFilters.find(
        (active) => active.name === filter.name
      );
      if (activeFilter) {
        return { ...filter, selected: activeFilter.selected };
      }
      return filter;
    });
  };
}
