import { Component, Input } from '@angular/core';
import { ModalType } from 'src/app/shared/models/constants.backoffice.schema';
import {
  IQueryDataOrders,
  ItemsDataTable,
  OrderProcessType,
  ReviewDecision,
} from 'src/app/shared/models/data-table.schema';
import {
  massiveColumnsApprover,
  massiveColumnsExecutor,
  reconcileType,
} from 'src/app/shared/models/order-types.schema';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';
import { GraphqlMassivetxnsService } from 'src/app/services/graphql/graphql-massivetxns.service';
import { ListBaseComponent } from 'src/app/shared/components/list-base/list-base.component';

@Component({
  selector: 'app-list-adjustment',
  templateUrl: `../../../../shared/components/list-base/list-base.component.html`,
  styleUrls: [
    '../../../../shared/components/list-base/list-base.component.scss',
  ],
})
export class ListAdjustmentComponent extends ListBaseComponent {
  @Input() override paramsQuery: IQueryDataOrders = {
    orderProcessType: OrderProcessType.RECONCILE,
    skip: 0,
    limit: 10,
  };
  override actionsType = OrderProcessType.RECONCILE;
  override columnsApprove = massiveColumnsApprover;
  override columnsExecutor = massiveColumnsExecutor;

  ordersListMassive: ItemsDataTable = {
    meta: {
      itemCount: 0,
      totalItems: 0,
      currentPage: 0,
      itemsPerPage: 0,
      totalPages: 0,
    },
    items: [],
  };

  constructor(
    public servicesMassivetxnsGql: GraphqlMassivetxnsService,
    sharedService: DataSharingService
  ) {
    super(sharedService);
  }

  override createConfigGetInitial() {
    this.paramsQuery = {
      orderProcessType: OrderProcessType.RECONCILE,
      skip: this.currentPage,
      limit: this.limitPage,
    };
    this.paramsDecision = {
      id: '',
      reviewDecision: ReviewDecision.APPROVED,
      reviewComments: '',
    };
    this.resultsDecision = `
        id: '',
        orderId: ''
        `;

    this.dataPagination = {
      skip: this.currentPage,
      limit: this.limitPage,
    };
  }

  override getOrdersByPageAccount() {
    this.loadListBranchs = true;
    this.modalSelectorTypes = ModalType.NotModal;
    this.dataLoad = false;
    this.servicesMassivetxnsGql.getOrders(this.paramsQuery).subscribe({
      next: (res) => {
        this.ordersList = res.body;
        if (!this.ordersList.items.length && !this.searchByFiltersEmpty) {
          this.dataLoad = true;
          this.activateInitNotOrders();
          this.modalSelectorTypes = ModalType.NotCreateOrders;
        }
        if (!this.ordersList.items.length && this.searchByFiltersEmpty) {
          this.dataLoad = true;
          this.activateInitNotOrdersSearchPropsModal();
          this.modalSelectorTypes = ModalType.NotOrdersSearch;
        }

        this.loadListBranchs = false;
      },
      error: () => {
        this.loadListBranchs = false;
        this.dataLoad = true;
        this.activateInitNotOrders();
        this.modalSelectorTypes = ModalType.NotOrders;
      },
    });
  }

  override getOrderTypeListAccount(orderType: string) {
    return reconcileType.find((order) => order.name === orderType)?.label;
  }

  override getOrderNumberById(id: string): string {
    return this.ordersListMassive.items.find((order) => order.id === id)!
      .orderId;
  }

  activateInitNotOrdersSearchPropsModal() {
    this.tableActions.initNotOrdersSearchPropsModal();
  }
}
