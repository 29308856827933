import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() img: string = assetUrl('svg/image-report.svg');
  @Input() subHeaderText: string = '';
  @Input() headerText: string = '';
  @Input() routesBack: string = '';
  @Input() routesNext: string = '';
  @Input() idParamRoute: string = '';
  @Output() onReload = new EventEmitter<void>();

  constructor(private router: Router) {}

  continueBack() {
    this.onReload.emit();
  }
}

