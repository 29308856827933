<div class="modal">
  <ng-container [ngSwitch]="modalType">
    <ng-template [ngSwitchCase]="modalTypeEnum.NotOrders">
      <ng-container *ngTemplateOutlet="notOrdersModal"></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="modalTypeEnum.NotFilters">
      <ng-container *ngTemplateOutlet="notFiltersModal"></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="modalTypeEnum.NotOrdersSearch">
      <ng-container *ngTemplateOutlet="notFiltersModal"></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="modalTypeEnum.NotCreateOrders">
      <ng-container *ngTemplateOutlet="notOrderList"></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="modalTypeEnum.NotModal">
    </ng-template>
    <ng-template [ngSwitchDefault]>
      <div>Error: modal inválido</div>
    </ng-template>
  </ng-container>
</div>

<ng-template #notOrdersModal>
  <div>
    <app-loading-error
    [image]="modalErrorProps.image"
    [text]="modalErrorProps.text"
    [valueAction]="modalErrorProps.valueAction"
    [headerText]="modalErrorProps.headerText"
    [subHeaderText]="modalErrorProps.subHeaderText"
    (closeChip)="clickEvent($event)"
    ></app-loading-error>
  </div>
</ng-template>

<ng-template #notOrderList>
  <app-empty-list
    title="No tienes órdenes aún"
    subtitle="Para crear una nueva orden presiona en el botón “Nueva orden”."
  ></app-empty-list>
</ng-template>

<ng-template #notFiltersModal>
  <div>
    <app-loading-error
      [image]="imageLoadError"
      [text]="'Borrar filtros'"
      [valueAction]="imageClearFilters"
      [headerText]="
        'No tienes órdenes que coincidan con los filtros que aplicaste'"
      [subHeaderText]="
        'Para ver todas tus órdenes borra los filtros que aplicaste.'
      "
      [orderId]="modalErrorProps.orderId"
      (closeChip)="clickEvent($event)"
      >></app-loading-error
    >
  </div>
</ng-template>

